import React, { Component } from "react";
import { IntlProvider, FormattedDate } from "react-intl";
import ChevronLeft from "react-icons/lib/io/ios-arrow-left";
import Header from "./Header";
import styled from 'styled-components';
import { device } from './device';
import {isMobile} from 'react-device-detect';
import { getNewsletterProps, getMoreNewsletters } from "./api";
import {Helmet} from "react-helmet";
import {RemoveScroll} from 'react-remove-scroll';
import {shrinkNewsletter } from './shrink.js';

const ContainerModal = styled.div`
  width:100%;
  height: 100%;
  position: relative;
`;

const LeftSide = styled.div`
height: 100%;
  width: 380px;
  display: block;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  border-right: solid 1px #edeff1;
  @media ${device.tablet} {
    width: 260px;
  }
  @media ${device.mobileL} {
    visibility: hidden;
  }
`;

const MerSideImage = styled.img `
  float:left;
  margin-right: 10px;
  object-fit: contain;
  width: 70px;
  height: 70px;
  background-color: white;
  @media ${device.tablet} {
  width: 60px;
  height: 60px;
  }
`;

const MerSideTitle = styled.h3 `
   font-size: 14px;
  margin-top: 2px;
  @media ${device.tablet} {
    font-size: 12px;
  margin-top: 2px;
  }
`;

const SideBarHeader = styled.div`
  margin: 10px 10px;
`;
const MerchantTitle = styled.h1`
 font-size: 22px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 26.4px;
  margin-left: 25px;
  margin-top: 5px;
  margin-bottom: 0px;
  padding-top: 0;
  display: inline;
`;

const ScrollContainer = styled.div`
  position: fixed;
  width: calc(100vw - 381px);
  max-height: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 10px;
  top: 56px;
  left: 381px;
  -webkit-overflow-scrolling: touch;
  @media ${device.tablet} {
    left: 261px;
    top: 56px;
    width: calc(100vw - 261px);
  }
  @media ${device.mobileL} {
    width: calc(100vw - 0px); 
    top: 75px;
    left: 0;
  }
`;

const IframeWrapper = styled.div`
  margin-top: 10px;
  border-top: 1px solid #edeff1;
  transform-origin: center top;
  transition: transform .05s linear;
  min-height: ${isMobile ? '100vh' : '280px'}; 
  @media ${device.tablet} {
    min-height: ${isMobile ? '100vh' : '280px'}; 
  }
  @media ${device.mobileL} {
    min-height: ${isMobile ? '100vh' : '280px'}; 
  }
`;

const ShowNewsletterModal = styled.div`
position: fixed;
  left: 0;
  top: 56px;
  width:100%;
  z-index: 100;
  height: calc(100vh - 56px);
  overflow: hidden;
  margin-top: 0;
  background-color: white;
  @media ${device.mobileL} {
    top: 81px;
  }
`;

const LeaveModal = styled.span`
position: fixed;
  top: 53px;
  left: 0px;
  z-index: 101;
  color: #d2d2d2;
  font-size: 36px;
  transition: color 100ms ease-in-out;
  @media ${device.mobileL} {
    top: 1px;
    font-size: 35px;
    left: 0px;
    position: absolute;
  }
`;

const NewsletterTitle = styled.div`
  margin-right: 25px;
  margin-left: 25px;
  color: #000;
  font-size: 26px;
  @media ${device.mobileL} {
    font-size: 20px;
    margin-right: 10px;
    margin-left: 40px;
  }
`;

const NewsletterFrom = styled.span`
 width: 100%;
  color: #969799;
  font-size: 16px;
  margin-left: 25px;
  margin-bottom: 20px;
  @media ${device.mobileL} {
    margin-left: 40px;
  }
`;

const CachedDate = styled.div`
  color: #969799;
  font-size: 16px;
  float: right;
  margin-right: 20px;
  @media ${device.tablet} {
    float: none;
    font-size: 14px;
    margin-left: 25px;
  }
  @media ${device.mobileL} {
    float: none;
    font-size: 12px;
    margin-left: 40px;
  }
`;

const ClearFix = styled.div `
  overflow: auto;
  clear: both;
`
function formatDate(created_at) {
  if (created_at === "") return "";
  else
    return (
      <IntlProvider locale="en">
        <FormattedDate value={created_at} />
      </IntlProvider>
    );
}
class Modal extends Component {
  componentDidMount() {
    shrinkNewsletter(window.self, document);
    this.setState({
      selectedIndex: 0,
    })
  }

  componentWillUnmount() {
    document.body.style.overflow = "visible";
  }

  setFirstSelect(index) {
    if (index === 0) {
      var element0 = document.getElementById(`mer-scoop0`)
      element0.classList.add("ms-selected")
      element0.classList.remove("ms-unslected")
    }
  }

  constructor(props) {
    super(props);
    document.body.style.overflow = "hidden";
    this.state = {
      items: [],
      newsletter_id: this.props.match.params.newsletter_id,
      created_at: "",
    };
    if (this.props.view === "yesModal") {
      this.state = {
        title: this.props.location.state.title,
        newsletter_id: this.props.match.params.newsletter_id,
        isModal: this.props.location.state.modal,
        merchant_name: this.props.location.state.merchant_name,
        created_at: this.props.location.state.created_at,
        parent_url: this.props.location.state.parent_url,
        image_url: this.props.location.state.image_url,
        items: []
      };
    } else if (this.props.view === "noModal") {
      getNewsletterProps(this.props.match.params.newsletter_id).then(result => {
        this.setState({
          title: result.item.title,
          isModal: this.props.modal,
          merchant_name: result.item.merchant_name,
          created_at: result.item.created_at,
          selectedIndex: 0
        });
        this.props.history.replace({
          state: {
            created_at: this.state.created_at,
            newsletter_id: this.state.newsletter_id,
            title: this.state.title,
            selectedIndex: this.state.selectedIndex
          }
        });
      });
    }

    getMoreNewsletters(this.props.match.params.newsletter_id).then(result => {
      this.setState({
        items: result.items
      });
    });
  }

  componentWillReceiveProps() {
    if (this.props.history.action === "POP" && this.props.history.location.state) {
      this.setState({
        created_at: this.props.history.location.state.created_at,
        newsletter_id: this.props.history.location.state.newsletter_id,
        title: this.props.history.location.state.title,
        selectedIndex: this.props.history.location.state.selectedIndex
      });
    }
  }

  onClose = () => {
    if (this.props.view === "yesModal") {
      this.props.history.push(`${this.state.parent_url}`);
      document.body.style.overflow = "visible";
    } else if (this.props.view === "noModal") {
      this.props.history.push(`/`);
    }
  };

  onSearch = query => {
    document.body.scrollTop = 0;
    this.setState({ query, list: [], nextPageToken: 1, message: "" });
  };

  changeNewsletter(newsletter_id, name, title, merchant_url, image_id, created_at, index) {
    this.makeSelected(index, this.state.selectedIndex)
    shrinkNewsletter(window.self, document);
    const isModal = this.props.view === "yesModal" ? true : false;
    this.setState({
      newsletter_id: newsletter_id,
      name: name,
      title: title,
      merchant_url: merchant_url,
      image_id: image_id,
      created_at: created_at,
      selectedIndex: index
    });
    this.props.history.push({
      pathname: `/${merchant_url}/newsletter-${newsletter_id}`,
      state: {
        modal: isModal,
        created_at: created_at,
        title: title,
        newsletter_id: newsletter_id,
        selectedIndex: index
      }
    });
    this.setState({
      previousSelectedIndex: this.state.selectedIndex,
      selectedIndex: index
    })
  }

  makeSelected(index, prevIndex) {
    var element0
    var element1
    if (index + prevIndex === 0) { // special case for when user forwards into modal
      this.setFirstSelect(0)
    } else {
      if (!index)
        element0 = document.getElementById(`mer-scoop0`)
      else
        element0 = document.getElementById(`mer-scoop${index}`)
      if (!prevIndex)
        element1 = document.getElementById(`mer-scoop0`)
      else
        element1 = document.getElementById(`mer-scoop${prevIndex}`)
        element0.classList.add("ms-selected")
        element0.classList.remove("ms-unselected")
        element1.classList.add("ms-unselected")
        element1.classList.remove("ms-selected")
    }
  }

  render() {
    return (
      <div>
          <Helmet>
                <meta charSet="utf-8" />      
                <script type="text/javascript">
                  {`
                  caja.load(document.getElementById('messagebox'), caja.policy.net.ALL, function(frame) {
                  var url = 'https://s3.amazonaws.com/imagecatcher/photos/${this.state.newsletter_id}/index.html?'
                  frame.code(url, 'text/html').run();
                  });
                  `}
                </script>          
              <title>{`${this.state.merchant_name} Newsletter: ${this.state.title} | Scoopfor.me`}</title>
            </Helmet>
        {this.props.view === "noModal" ? (
          <Header onSearch={this.onSearch} history={this.props.history} />
        ) : (
            ""
          )}
          <RemoveScroll forwardProps >
                  <ShowNewsletterModal>
          <ContainerModal>
            <LeftSide>
              <div className="sidebar-top" id="sidebar-top">
                <SideBarHeader>
                  <MerchantTitle>{this.state.merchant_name}</MerchantTitle>
                </SideBarHeader>
              </div>
              <div className="more-from-merchant-body">
                <div>
                  {this.state.items.map((item, index) => (
                    <div
                      className={`${index === this.state.selectedIndex ? "mer-scoop ms-selected" : "mer-scoop ms-unselected"}`}
                      id={`mer-scoop${index}`}
                      key={`mer_scoop_${index}`}
                      onClick={() =>
                        this.changeNewsletter(
                          item.newsletter_id,
                          this.state.merchant_name,
                          item.title,
                          item.merchant_url,
                          item.id,
                          item.created_at,
                          index
                        )
                      }
                    >
                      <MerSideImage
                        src={`${index === 0 && this.state.image_url ? this.state.image_url : item.url}`}
                        alt={item.alt_tag ? item.alt_tag + " in " + this.state.merchant_name + " newsletter": this.state.merchant_name +" " +this.state.title }
                        id={`relScoop_${item.id}`}
                        ref={`Image_${index}`}
                        key={`Image_${index}`}
                      />
                      <h4 className={"mer-scoop-date"}>
                        {formatDate(item.created_at)}{" "}
                      </h4>
                      <MerSideTitle>{item.title}</MerSideTitle>
                    </div>
                  ))}
                </div>
              </div>
            </LeftSide>
                <ScrollContainer>
                <LeaveModal onClick={this.props.onClose || this.onClose}>
          <ChevronLeft />
        </LeaveModal>
                   <NewsletterTitle>
               {this.state.title}
            </NewsletterTitle>
            <NewsletterFrom>
               {this.state.merchant_name} Newsletter 
            </NewsletterFrom>
            <CachedDate>
              cached email on {formatDate(this.state.created_at)}
            </CachedDate>
            <ClearFix/>
            <IframeWrapper className="iframe" id="messagebox"  key={`${this.state.newsletter_id}`}>
            </IframeWrapper>
            </ScrollContainer>
          </ContainerModal>
        </ShowNewsletterModal>
        </RemoveScroll>
      </div>  
    );
  }
}

export default Modal;