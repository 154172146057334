import axios from 'axios'

function getItems(items) {
  return items.map(item => {
    const width = item.thumb_width;
    const height = item.thumb_height;
    const url = `https://s3.amazonaws.com/imagecatcher/photos/${item.newsletter_id}/thumb/${item.image_file_name}`;
    const title = item.title;
    const merchant_name = item.merchant_name;
    const created_at = item.created_at;
    const merchant_url = item.merchant_url;
    const newsletter_id = item.newsletter_id
    const alt_tag = item.alt_tag
    return {
      width,
      height,
      url,
      title,
      merchant_name,
      merchant_url,
      created_at,
      newsletter_id,
      alt_tag
    };
  });
}

function getNewsletter(item) {
  const merchant_name=item.merchant_name;
  const title=item.original_title
  const merchant_url=item.merchant_url;
  const newsletter_id=item.newsletter_id;
  const created_at=item.created_at;
  const merchant_id=item.merchant_id;
  return {merchant_name, title, merchant_url, newsletter_id, created_at, merchant_id}; 
}

export default function getNextPage(query, pageNum, sort) {
  var url = "https://api.scoopfor.me/api?";
  
  if (query !== '' && query !== undefined)
    url = url + `query=${query}`;
  if (sort !== '')
    url = url + `&sort=${sort}`;
  url = url + `&page=${pageNum}`;

  return axios.get(url)
  .then(e => (e.error ? Promise.reject({ error: e.error.message }) : e))
    .then(({ data }) => ({
      nextPageToken: (pageNum +1),
      items: getItems(data)
    }))
    .catch(e => ({ error: e.error || "An Error Has Occurred", nextPage: -1, items: [] }));
  }

  export function getNewsletterProps(newsletter_id) { // gets properties of newsletter using an axios call and the id
   var web_url = "https://api.scoopfor.me";
    return axios.get(`${web_url}/image/getimagebynewsletter?newsletter_id=${newsletter_id}`)
    .then(({ data }) => ({
      item: getNewsletter(data)
    }))
    .catch(e => ({ error: e.error || "An Error Has Occurred" }));
  }
  
  export function getMoreNewsletters(newsletter_id) { // gets properties of newsletter using an axios call and the id
    var web_url = "https://api.scoopfor.me";
     return axios.get(`${web_url}/api?newsletter_id=${newsletter_id}`)
     .then(({ data }) => ({
      items: getItems(data)
     }))
     .catch(e => ({ error: e.error || "An Error Has Occurred" }));
   }

