import React from "react";
import Header from "./components/Header";
import { GridLayout } from "@egjs/react-infinitegrid";
import getNextPage from "./components/api";
import Item from "./components/Item";
import {isMobile} from 'react-device-detect';
import styled from 'styled-components';
import { device } from './components/device';


const GridWrapper = styled.div`
    margin-top: 60px;
  @media ${device.mobileL} {
    margin-top: 75px;
  }
`;

function getItems(items, groupKey, start) {
  return items.map((props, i) => (
    <Item groupKey={groupKey} key={`item${start + i}`} {...props} />
  ));
}

const Message = ({ message }) =>
  message ? <div className="nomore">{message}</div> : null;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      query: this.props.match.params.query,
      list: [],
      nextPageToken: 1,
      sort: "",
      message: ""
    };
  }

  setQuery = () => {
    const { query = "" } = this.props.match.params;
    this.setState({
      value: query,
      query,
      list: [],
      nextPageToken: 1,
      message: ""
    });
  };

  onAppend = e => {
    const groupKey = parseFloat(e.groupKey || 0) + 1;
    const pageNum = this.state.nextPageToken;

    if (pageNum === -1) {
      return;
    }
    e.startLoading();
    getNextPage(
      this.state.query,
      pageNum,
      this.state.sort,
      this.state.newsletter_id
    ).then(result => {
      const { nextPageToken, items, error } = result;
      const list = this.state.list;
      const length = items.length;
      var message = "";
      if (length === 0) {
        if (pageNum > 1) {
          message = `No more results found`;
        } else {
          message = `No results found`;
        }

        if (this.state.query) message = message + ` for ${this.state.query}`;
      }

      this.setState({
        message,
        nextPageToken: (length && nextPageToken) || -1,
        list: error ? [] : list.concat(getItems(items, groupKey, list.length))
      });
      (message || !length) && e.endLoading();
    });
  };
  onLayoutComplete = e => {
    if (this.state.query !== '' && this.state.nextPageToken < 3)
    {
      document.activeElement.blur();
    }
    !e.isLayout && e.endLoading();
  };

  render() {
    return (
      <div>
        <Header
          setQuery={this.setQuery}
          history={this.props.history}
          match={this.props.match}
        />
        <GridWrapper>
        <GridLayout
          isConstantSize={true}
          threshold={1000}
          margin={isMobile ? 10 : 20}
          align="center"
          onAppend={this.onAppend}
          loading={<div className="loading" />}
          onLayoutComplete={this.onLayoutComplete}
        >
          {this.state.list}
        </GridLayout>
        <Message message={this.state.message} />
        </GridWrapper>
      </div>
    );
  }
}

export default App;
