import React from "react";
import FaSearch from "react-icons/lib/fa/search";
import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import { device } from './device';

const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0px;
  height: 55px;
  z-index: 1;
  border-bottom: 1px solid #edeff1;
  background: white;
  @media ${device.mobileL} {
    height: 80px;
  }
`;

const LogoImg = styled.img`
  position: absolute;
  left: 10px;
  top: 10px;
  width: 220px;
  height: 40px;
  @media ${device.laptop}{
    left: 5px;
  }
  @media ${device.mobileL} {
    left: 5px;
    width: 165px;
    height: 30px;}
`;

const SearchBar = styled.form`
  position: absolute;
  left:350px;
  top: 10px;
  right: 0;
  width: 500px;
  @media ${device.laptop}{
    left: 240px;
  }
  @media ${device.mobileL}{
    width: 400px;
    left: 10px;
    top: 38px;
  }
  
`;

const Button = styled.button`
  background-color: white;
  color: #ea556f;
  border: none;
  padding: 0px;
  font-size: 0;
  line-height: 16px;
  transition: background-color 100ms ease-in-out;
  &:hover {
    color: black;
    cursor: pointer;
  }
  @media ${device.mobileL} {
   
  }
  `;

const Icon = styled.span`
  font-size: 18px;
  `;

const Input = styled.input`
  vertical-align: middle;
  width: calc(100% - 100px);
  height: 32px;
  font-size: 16px;
  border: none;
  outline: none;
  padding-left: 6px;
  &:required {
    box-shadow:none;
  }
  `;

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      message: ""
    };
  }

  componentDidMount() {
    if (this.props.match) {
      const { query = "" } = this.props.match.params;
      this.setState({
        value: query
      });
      this.props.setQuery();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match && (prevProps.match.params.query !== this.props.match.params.query)) {
      const { query = "" } = this.props.match.params;
      this.setState({
        value: query
      });
      this.props.setQuery();
    }
  }

  onChange = event => {
    this.setState({ value: event.target.value });
  };


  onSubmit = event => {
    event.preventDefault();
    this.props.history.push(`/search/${this.state.value}`);
  };

  render() {
    const { value } = this.state;

    return (
      <HeaderWrapper>
        <a href="/">
          <LogoImg
            src="../scoopforme-logo2x.png"
            alt="scoopfor.me logo"
          />
        </a>
        <SearchBar onSubmit={this.onSubmit} name="site-search" role="search" action=".">
          <Button type="search" onClick={this.onSubmit}>
           <Icon><FaSearch /></Icon>
          </Button>
          <Input
            type="input"
          
            required
            placeholder= {isMobileOnly ? 'Search zillions of newsletters' : 'Search zillions of newsletters for rad stuff'} 
            value={value}
            onChange={this.onChange}
            ref={e => {
              e && (this.input = e);
            }}
            onKeyUp={e => {
              if (!this.input || e.keyCode !== 13) {
                return;
              }
              this.onSubmit(e);
            }}
          />
        </SearchBar>
      </HeaderWrapper>
    );
  }
}
