import React from "react";
import ReactDOM from "react-dom";
import { Router, Route } from "react-router-dom";
import Beta from './Beta'
import Routes from "./Routes";
import "./css/index.css";
import registerServiceWorker from './registerServiceWorker';
import createHistory from "history/createBrowserHistory";
const history = createHistory();
class Root extends React.Component {

  constructor() {
    super()
    this.state = {
      authorized: false,
    }
    this.isAuthorized = this.isAuthorized.bind(this)
  }

  isAuthorized() {
    this.setState({
      authorized: true
    })
  }
  // Snoopy visitors defense mechanism
  componentDidMount() {
  }

render(){
  var password = localStorage.getItem('password') // check if password is stored in localStorage
  if (password !== "dealcatcher") { // if not, go to landing page, else render the application
    return (
      <Router history={history}>
        <div>
          <Route path="/" render={(props) => (
            <Beta
              isAuthorized = {this.isAuthorized} {...props} 
            />)} 
          />
        </div>
      </Router>
    )
  } else {
  return(
  <Router history={history}>
    <Route component={Routes} />
  </Router>
  )
}}
}
ReactDOM.render(<Root />, document.getElementById('root'));
registerServiceWorker();