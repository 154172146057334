import React, {Component} from 'react'
import './css/landingPage.css'

// Landing page.
// There are 4 pages that cycle through in the render() function.
class Beta extends Component {

  constructor() {
    super()
    this.state = {
      authorized: false,
      pageNum: 1,
      failedAttempt: false
    }
  }
  // Password is saved in localStorage so that the user does not have to sign in every
  // page reload. 
  trySignIn(event) {
    event.preventDefault()
    if(this.password.value === "dealcatcher") {
      localStorage.setItem('password', this.password.value)
      this.setState({
        authorized: true
      })
    }
    else {
      this.setState({
        failedAttempt: true
      })
    }
  }
  goHome() {
    this.props.isAuthorized()
    window.location.reload();

  }

  unauthorized() {
    if (this.state.failedAttempt) {
      return (
        <div className = "unauthorized_landing">
          Invalid password. Sorry!
        </div>
      )
    }
  }

  render() {
    if (!this.state.authorized) {
      return (
        <div className="landingPageBody">
          <div className="image-beta">
            <img className="logo" src='../scoopforme-logo2x.png' alt="scoopfor.me logo" />
          </div>
          <div className="password_form">
            <form onSubmit={this.trySignIn.bind(this)}>
            <label>
              Password</label>
              {this.unauthorized()}
              <br /><input type='password' ref={node => {this.password = node}}/>
              <br />
              
              <button onClick={() => this.goHome()}>Try Beta</button>
            </form>
          </div>
        </div>
      )
    } 
  }
}

export default Beta