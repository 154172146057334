import React from "react";
import { IntlProvider, FormattedRelative } from "react-intl";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { device } from './device';
import { isMobile } from 'react-device-detect';

const ItemWrapper = styled.div`
  position: fixed;
  width: ${isMobile ? '170px' : '280px'}; 
  border-radius: 7px;
  text-align: left;
  background-color: #ffffff;
  @media ${device.mobileL} {
    border-radius: 5px;
  }
  @media ${device.mobileS} {
    border-radius: 5px;
    width: 140px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ImageContainer = styled.span`
  overflow: hidden;
`;

const ItemImg = styled.img`
  max-width: 100%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  height: auto;
  display: block;
  margin: 0 auto;
  @media ${device.mobileL} {
    border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  }
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: 200;
  color: black;
  margin-bottom: 0;
  padding: 10px 10px 10px 10px;
`;

const Merchant = styled.div`
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 600;
  text-align: left;
  color: gray;
  padding: 0px 10px 0px 10px;
`;

const Time = styled.div`
  font-size: 12px;
  margin: 5px 0 10px 10px;
  font-weight: normal;
`;

const Item = ({
  width,
  height,
  url,
  title,
  merchant_name,
  created_at,
  newsletter_id,
  merchant_url,
  alt_tag
}) => (

    <ItemWrapper>
      <StyledLink
        to={{
          // link to modal. passes all necessary parameters from its image properties.
          pathname: `/${merchant_url}/newsletter-${newsletter_id}`,
          state: {
            modal: true, // so it is modal
            title: title,
            merchant_name: merchant_name,
            newsletter_id: newsletter_id,
            created_at: created_at,
            parent_url: window.location.pathname,
            image_url: url,
            selectedIndex: 0
          } // rest of html code is the internal scoop box elements.
        }}
      >
        <ImageContainer>
          <ItemImg width={width} height={height} alt={alt_tag ? alt_tag + " in " + merchant_name + " newsletter": merchant_name +" " +title } src={url}  />
          <Title>{title}</Title>
        </ImageContainer>
      </StyledLink>
      <Merchant>{merchant_name}</Merchant>
      <Time>
        <IntlProvider locale="en">
          <FormattedRelative value={created_at} />
        </IntlProvider>
      </Time>
    </ItemWrapper>
  );

export default Item;
