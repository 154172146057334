import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import App from "./App";
import Modal from "./components/Modal";

class Routes extends Component {
  previousLocation = this.props.location;

  componentWillUpdate(nextProps) {
    const { location } = this.props;
    if (
      nextProps.history.action !== "POP" &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location;
    }
  }

  render() {
    const { location } = this.props;
    const isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    );

    return (
      <div>
        <Switch location={isModal ? this.previousLocation : location}>
          <Route exact path="/" component={App} />
          <Route path="/search/:query?" component={App} />
          <Route
            path="/:merchant_url/newsletter-:newsletter_id"
            render={props => <Modal view="noModal" {...props} />}
          />
        </Switch>

        {isModal ? (
          <Route
            path="/:merchant_url/newsletter-:newsletter_id"
            render={props => <Modal view="yesModal" {...props} />}
          />
        ) : null}
      </div>
    );
  }
}
export default Routes;
